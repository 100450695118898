<template>
  <div v-if="$route.name === 'Intro' || !loading">
    <div class="intro">
      <section class="header">
        <router-link :to="listingUrl" v-if="listingUrl">
          <span>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.6 11.8C6.375 11.8 6.175 11.725 6.025 11.575L1.225 6.775C0.9 6.475 0.9 5.95 1.225 5.65L6.025 0.85C6.325 0.525 6.85 0.525 7.15 0.85C7.475 1.15 7.475 1.675 7.15 1.975L2.925 6.2L7.15 10.45C7.475 10.75 7.475 11.275 7.15 11.575C7 11.725 6.8 11.8 6.6 11.8Z" fill="#545454" />
            </svg>
          </span>
          {{ t['go_back'] }}
        </router-link>
        <h3>{{ t['welcome'] }}</h3>
        <p>{{ t['you_found_perfect_car'] }}</p>
      </section>
      <section @click="listingClick" :class="{ carInfo: true, clickable: $route.name == 'Intro' }" v-if="details != null && details.id != null">
        <img :src="imageUrl" alt="car-image" />
        <div>
          <div class="overlay" />
          <!-- <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.1875 8C8.84375 8.03125 8.65625 8.21875 8.625 8.5625V11.1875C8.60938 11.2969 8.54688 11.3594 8.4375 11.375H1.3125C1.20312 11.3594 1.14062 11.2969 1.125 11.1875V4.0625C1.14062 3.95312 1.20312 3.89063 1.3125 3.875H3.9375C4.28125 3.84375 4.46875 3.65625 4.5 3.3125C4.46875 2.96875 4.28125 2.78125 3.9375 2.75H1.3125C0.9375 2.76563 0.625 2.89062 0.375 3.125C0.140625 3.375 0.015625 3.6875 0 4.0625V11.1875C0.015625 11.5625 0.140625 11.875 0.375 12.125C0.625 12.3594 0.9375 12.4844 1.3125 12.5H8.4375C8.8125 12.4844 9.125 12.3594 9.375 12.125C9.60938 11.875 9.73438 11.5625 9.75 11.1875V8.5625C9.71875 8.21875 9.53125 8.03125 9.1875 8ZM11.4375 0.5H7.5C7.15625 0.53125 6.96875 0.71875 6.9375 1.0625C6.96875 1.40625 7.15625 1.59375 7.5 1.625H10.0781L4.28906 7.41406C4.07031 7.67969 4.07031 7.94531 4.28906 8.21094C4.39844 8.32031 4.53125 8.375 4.6875 8.375C4.84375 8.375 4.97656 8.32031 5.08594 8.21094L10.875 2.42188V5C10.9062 5.34375 11.0938 5.53125 11.4375 5.5625C11.7812 5.53125 11.9688 5.34375 12 5V1.0625C11.9688 0.71875 11.7812 0.53125 11.4375 0.5Z"
              fill="#AFAFAF"
            />
          </svg> -->
          <h1>{{ fullName }}</h1>
          <p>
            {{ details.variant }}
            <svg width="3" height="3" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 0.5625C0.929688 0.5625 0.0625 1.42969 0.0625 2.5C0.0625 3.57031 0.929688 4.4375 2 4.4375C3.07031 4.4375 3.9375 3.57031 3.9375 2.5C3.9375 1.42969 3.07031 0.5625 2 0.5625Z" fill="#8e8e8e" />
            </svg>
            {{ details.year }}
          </p>
          <p>{{ retailPriceString }}</p>
        </div>
      </section>
      <section class="stepsGrid">
        <div class="stepsSession">
          <div class="iconHeader">
            <Cart />
            <h1>{{ t['during_your_checkout'] }}</h1>
          </div>
          <ul>
            <li>{{ t['you_fill_personal_details'] }}</li>
            <li>{{ t['you_add_trade_in'] }}</li>
            <li>{{ t['you_decide_how_apply_finance'] }}</li>
            <li>{{ t['you_add_your_date'] }}</li>
          </ul>
        </div>
        <div class="stepsSession">
          <div class="iconHeader">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.15594 7.72381C3.93199 7.96756 3.61613 8.11483 3.28199 8.07928C2.94785 8.13514 2.62488 8.00311 2.38824 7.72381L0.356941 5.69256C-0.11898 5.26092 -0.11898 4.48905 0.356941 4.01323C0.832812 3.53741 1.60469 3.53741 2.08051 4.01323L3.20328 5.13397L6.00234 2.02819C6.44922 1.52799 7.22109 1.48737 7.72383 1.9378C8.22149 2.38823 8.26211 3.15858 7.81016 3.65928L4.15594 7.72381ZM4.15594 15.8488C3.93199 16.0926 3.61613 16.2398 3.28199 16.2043C2.94785 16.2601 2.62488 16.1281 2.38824 15.8488L0.356941 13.8176C-0.11898 13.3859 -0.11898 12.614 0.356941 12.1367C0.832812 11.6644 1.60469 11.6644 2.08051 12.1367L3.20328 13.259L6.00234 10.1512C6.44922 9.6535 7.22109 9.61288 7.72383 10.0648C8.22149 10.5117 8.26211 11.2836 7.81016 11.7863L4.15594 15.8488ZM0 21.125C0 19.7793 1.09129 18.6875 2.4375 18.6875C3.78371 18.6875 4.875 19.7793 4.875 21.125C4.875 22.4707 3.78371 23.5625 2.4375 23.5625C1.09129 23.5625 0 22.4707 0 21.125Z" fill="#5bc2f4" />
              <path opacity="0.4" d="M9.75 19.5H24.375C25.2738 19.5 26 20.2262 26 21.125C26 22.0238 25.2738 22.75 24.375 22.75H9.75C8.85117 22.75 8.125 22.0238 8.125 21.125C8.125 20.2262 8.85117 19.5 9.75 19.5ZM13 11.375H24.375C25.2738 11.375 26 12.1012 26 13C26 13.8988 25.2738 14.625 24.375 14.625H13C12.1012 14.625 11.375 13.8988 11.375 13C11.375 12.1012 12.1012 11.375 13 11.375ZM13 6.5C12.1012 6.5 11.375 5.77383 11.375 4.875C11.375 3.9777 12.1012 3.25 13 3.25H24.375C25.2738 3.25 26 3.9777 26 4.875C26 5.77383 25.2738 6.5 24.375 6.5H13Z" fill="#5bc2f4" />
            </svg>
            <h1>{{ t['what_happens_next'] }}</h1>
          </div>
          <ul>
            <li>
              <b>{{ t['trade_in'] }}:</b> {{ t['receive_offer_one_day'] }}
            </li>
            <li>
              <b>{{ t['financing'] }}:</b> {{ t['you_receive_decision'] }}
            </li>
            <li>
              <b>{{ t['sales_agreement'] }}:</b> {{ t['we_send_you_contract'] }}
            </li>
            <li>
              <b>{{ t['payment'] }}:</b> {{ t['you_transfer_your_payment'] }}
            </li>
            <li>
              <b>{{ t['delivery'] }}:</b> {{ t['we_deliver_your_car'] }}
            </li>
          </ul>
        </div>
        <div class="thingsToNote">
          <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.4" d="M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM8 4C8.55219 4 9 4.44781 9 5C9 5.55219 8.55219 6 8 6C7.44781 6 7 5.55312 7 5C7 4.44688 7.44688 4 8 4ZM9.25 12H6.75C6.3375 12 6 11.6656 6 11.25C6 10.8344 6.33594 10.5 6.75 10.5H7.25V8.5H7C6.58594 8.5 6.25 8.16406 6.25 7.75C6.25 7.33594 6.5875 7 7 7H8C8.41406 7 8.75 7.33594 8.75 7.75V10.5H9.25C9.66406 10.5 10 10.8359 10 11.25C10 11.6641 9.66562 12 9.25 12Z" fill="#5bc2f4" />
              <path d="M8 6C8.55219 6 9 5.55219 9 5C9 4.44781 8.55219 4 8 4C7.44781 4 7 4.44687 7 5C7 5.55313 7.44687 6 8 6ZM9.25 10.5H8.75V7.75C8.75 7.3375 8.41563 7 8 7H7C6.5875 7 6.25 7.3375 6.25 7.75C6.25 8.1625 6.5875 8.5 7 8.5H7.25V10.5H6.75C6.3375 10.5 6 10.8375 6 11.25C6 11.6625 6.3375 12 6.75 12H9.25C9.66406 12 10 11.6641 10 11.25C10 10.8359 9.66563 10.5 9.25 10.5Z" fill="#5bc2f4" />
            </svg>
            <h3>{{ t['things_to_note'] }}:</h3>
          </div>
          <ul>
            <li>
              {{ t['you_free_of_charge'] }}
            </li>
            <li>
              {{ t['you_will_not_be_expected_payment'] }}
            </li>
          </ul>
        </div>
      </section>
      <section class="assistanceBanner">
        <h3>{{ t['here_when_you_need_us'] }}</h3>
        <div class="contactUs">
          <div class="imgContainer">
            <img src="@/resources/assistance-img.webp" />
            <div class="emailInfo">
              <EmailIcon />
              <a href="mailto:info@starmark.dk">info@starmark.dk</a>
            </div>
            <div />
            <div class="phoneInfo">
              <PhoneIcon />
              <a href="tel:+4572116000">7211 6000</a>
            </div>
            <div />
          </div>
        </div>
        <div class="infoGrid" v-if="$route.name == 'Intro'">
          <div class="infoContainer">
            <div class="iconContainer">
              <FourteenDaysReturn />
            </div>
            <h3>{{ t['thirty_days_return'] }}</h3>
            <p>{{ t['full_transparency'] }}</p>
            <p>{{ t['thirty_days_test_drive'] }}</p>
          </div>
          <div class="infoContainer">
            <div class="iconContainer">
              <OneYearWarranty />
            </div>
            <h3>{{ t['one_year_guarantee'] }}</h3>
            <p>{{ t['we_believe_in_peace_of_mind'] }}</p>
            <p>{{ t['no_extra_cost_guarantee'] }}</p>
          </div>
          <div class="infoContainer">
            <div class="iconContainer">
              <Quality />
            </div>

            <h3>{{ t['quality_assured'] }}</h3>
            <p>{{ t['we_believe_almost_new'] }}</p>
            <p>{{ t['our_cars_are_certified'] }}</p>
          </div>
          <div class="infoContainer">
            <div class="iconContainer">
              <FreeDelivery />
            </div>
            <h3>{{ t['free_delivery'] }}</h3>
            <p>{{ t['total_convinience'] }}</p>
            <p>{{ t['your_car_delivered_to_door'] }}</p>
          </div>
        </div>
      </section>
    </div>
    <ModalEmailError v-if="showWrongEmailModal" :show="showWrongEmailModal" @logout="logout" @browse-similar-cars="browseSimilarCars" />
    <div class="checkoutStickyContainer">
      <div class="contentHolder">
        <div class="priceDetails">
          <p>{{ t['total_to_pay'] }}</p>
          <p class="retailPricing">{{ retailPriceString }}</p>
        </div>
        <button :disabled="loading" @click="checkout">
          {{ t['start_checkout'] }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { JOURNEY_INTRO } from '../../lib/constants'
import FourteenDaysReturn from '../../resources/icons/fourteen_days_return.svg'
import OneYearWarranty from '../../resources/icons/one_year_warranty.svg'
import Quality from '../../resources/icons/quality.svg'
import FreeDelivery from '../../resources/icons/free_delivery.svg'
import PhoneIcon from '../../resources/icons/telephone.svg'
import EmailIcon from '../../resources/icons/email.svg'
import Cart from '../../resources/icons/cart.svg'
import car from '../../resources/car.webp'
import { querier } from '../../lib/querierMixin'
import { getImageUrl } from '../../lib/helper'
import ModalEmailError from './ModalEmailError.vue'
import { ROUTES } from '../../router/index'

export default {
  name: 'JourneyIntro',
  components: { FourteenDaysReturn, OneYearWarranty, Quality, FreeDelivery, PhoneIcon, EmailIcon, Cart, ModalEmailError },
  mixins: [querier],
  data() {
    return {
      listingId: null,
      details: {},
      loading: false,
      error: null,
      orderCreated: false,
      car: car,
      showWrongEmailModal: false
    }
  },
  computed: {
    t() {
      return JOURNEY_INTRO
    },
    fullName() {
      if (this.details == null) return null
      return this.details.name ?? this.details.model.family.brand.name + ' ' + this.details.model.name
    },
    retailPriceString() {
      if (this.details?.retailPrice?.value == null) return null
      return this.$root.formatNumber(this.details.retailPrice.value, 0, this.details.retailPrice.currency)
    },
    imageUrl() {
      if (!this.details?.images) return car
      return this.details.images.length > 0 ? getImageUrl(this.details.id, this.details.images[0], '300') : car
    },
    listingUrl() {
      if (this.details?.model == null) return null
      return `${ROUTES.LISTING}/${this.$root.slugify(this.details.model.family.brand.name)}/${this.$root.slugify(this.details.model.name)}-${this.$root.slugify(this.details.variant)}/${this.details.id}`
    }
  },
  mounted() {
    this.checkForGo()
    this.getListingId().then(r => {
      this.listingId = r
      this.loadDetails()
    })
  },
  methods: {
    async checkForGo() {
      if (this.$route.hash === '#go') {
        this.loading = true
        const user = await window.seezSdk.getCurrentUser()
        this.loading = false
        if (user != null) this.checkout()
      }
    },
    async getListingId() {
      this.loading = true
      try {
        if (this.$route.params.id) return this.$route.params.id
        if (this.$route.params.externalId) {
          const result = await this.queryApi(`{listingsByExternalId(externalId: "${this.$route.params.externalId}", clientId: "${this.$route.params.origin}")}`)
          return result.listingsByExternalId[0]
        }
        if (this.$route.params.reference) {
          const result = await this.queryApi(`{listingsByDealerReference(reference: "${this.$route.params.reference}", clientId: "${this.$route.params.origin}")}`)
          return result.listingsByDealerReference[0]
        }
      } catch (error) {
        console.error('Invalid listing identifier', error)
      } finally {
        this.loading = false
      }
    },
    async loadDetails() {
      this.loading = true
      this.error = null
      try {
        const query = `{
          listing(id: ${this.listingId}) {
            id name kilometrage registrationDate variant color year currency retailPrice { value currency } state images
            model { id name family { id name brand { id name }}} transmission { name }
            dealership { id name } fuelType { name } bodyType { name } targetSite { id logo brandingHighlightColor }
          }
        }`
        const result = await this.queryApi(query)
        this.details = result.listing
        this.trackLoadDetails(result.listing.targetSite.id, result.listing.id)
      } catch (error) {
        console.error(error)
        this.error = 'Error loading listing details'
      } finally {
        this.loading = false
      }
    },
    async checkout() {
      if (this.loading) return

      const user = await window.seezSdk.getCurrentUser()

      if (user == null) {
        this.$root.track(this.$root.events.SHOW_LOGIN, {
          name: this.listingId,
          listing_id: parseInt(this.listingId),
          listing: this.details,
          listing_price: this.details.retailPrice.value
        })
        if (this.$route.hash !== '#go') {
          await this.$router.replace({ hash: '#go', query: { orderId: this.$route.query.orderId } })
        }
        // this.$root.showLogin()
        window.seezSdk.showLogin(null, true, {
          branding: '<div class="branding">&nbsp;</div>'
        })
        return
      }

      this.loading = true
      if (this.orderCreated) {
        this.loading = false
        return
      }

      const orderId = this.$route.query.orderId ?? (await window.seezSdk.createOrder(this.listingId, '/soegeresultater'))

      if (orderId) {
        await this.$root.track('start_checkout_button', {
          event_label_car_id: this.listingId,
          listingId: this.listingId,
          listing_id: parseInt(this.listingId),
          listing_make: this.details.model.family.brand.name,
          listing_model: this.details.model.name,
          listing_variant: this.details.variant,
          listing_body_type: this.details.bodyType.name,
          listing_price: this.details.retailPrice.value
        })
        this.directToCheckout(orderId)
      }

      this.loading = false
    },
    async getOrder(orderId) {
      this.loading = true
      if (!orderId) {
        return null
      }
      const gqlQuery = ` { order(id: ${orderId}) { state customerInfo { email } } } `

      try {
        const { order } = await this.queryApi(gqlQuery)
        this.loading = false
        return order
      } catch (e) {
        console.log(e)
        this.showWrongEmailModal = true
        this.loading = false
        return null
      }
    },
    async directToCheckout(orderId) {
      this.savePageReferrer()
      await this.$root.track(this.$root.events.START_PJ, {
        listing_id: parseInt(this.listingId),
        properties: { type: 'qr_code_start_pj', name: this.listingId, listing: this.details, listing_price: this.details.retailPrice.value, target_site_id: this.details.targetSite.id }
      })
      window.location = `${window.location.origin}/checkout/?order=${orderId}`
    },
    listingClick() {
      if (this.$route.name !== 'Intro' || this.listingUrl == null) return

      const link = document.createElement('a')
      link.href = this.listingUrl
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async logout() {
      await window.seezSdk.logout()
      this.showWrongEmailModal = false
    },
    browseSimilarCars() {
      return this.$router.push(`/soegeresultater/?brands=${this.details.model.family.brand.id}&models=${this.details.model.id}`)
    },
    trackLoadDetails(targetSiteId, listingId) {
      return this.$root.track(this.$root.events.LOAD_INTRO_PAGE, { target_site_id: targetSiteId, listingId })
    },
    savePageReferrer() {
      localStorage.setItem('page_referrer', window.location.href)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(3px);
  display: grid;
  place-items: center;
  z-index: 3;

  > * {
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}

.intro {
  align-self: start;
  overflow: auto;
  display: grid;
  grid-template-rows: 1fr auto;
  color: black;

  > .header {
    margin: auto;
    position: relative;
    min-width: 43.5rem;
    max-width: 40%;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 32px;
    > a {
      position: absolute;
      font-size: 12.8px;
      text-decoration: none;
      left: 0;
      & svg {
        margin-right: 4px;
      }
    }
    > p {
      font-family: 'Corporate S';
      letter-spacing: -0.02rem;
      font-size: 1rem;
      font-weight: 400;
    }
    h3 {
      margin-bottom: 1rem;
      font-family: 'Corporate A Condensed';
      font-size: 2.25rem;
      font-weight: 400;
      line-height: 28px;
    }
    @media screen and (max-width: 30rem) {
      margin-top: 120px;
      display: flex;
      flex-direction: column;
      min-width: 100%;
    }
  }

  @media screen and (max-width: 50rem) {
    align-self: start;
    overflow: auto;
    display: block;
    padding: 0em 1em;
  }

  @media screen and (max-width: 30rem) {
    overflow-x: hidden;
  }

  > .carInfo {
    display: flex;
    border-radius: 1rem;
    max-height: 9rem;
    min-width: 43.5rem;
    max-width: 40%;
    margin: auto;
    position: relative;
    background-color: white;
    &.clickable {
      cursor: pointer;
    }

    @media screen and (max-width: 50rem) {
      min-width: 100%;
    }

    > img {
      width: 50%;
      height: auto;
      object-fit: cover;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;

      @media screen and (max-width: 50rem) {
        width: 14rem;
        height: auto;
        object-fit: cover;
      }

      @media screen and (max-width: 30rem) {
        width: 9rem;
        height: auto;
        object-fit: cover;
      }
    }

    > div {
      padding: 1rem;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 1rem;
      border-left: none;
      border: 0.5px solid #bbbbbb;
      border-radius: 0rem 1rem 1rem 0rem;
      > svg {
        position: absolute;
        right: 1rem;
        top: 1rem;
      }

      > h1 {
        font-size: 1.5em;
        margin-block-start: 0;
        margin-block-end: 0.2em;

        @media screen and (max-width: 50rem) {
          font-size: 1.2rem;
        }
      }

      > p {
        margin-block-start: 0;
        margin-block-end: 0.5em;
        color: #8e8e8e;

        > svg {
          vertical-align: middle;
        }
      }

      p:last-child {
        text-align: end;
        font-weight: 900;
        font-size: 1.2rem;
        margin: 0 0.5em;
        transform: translateY(0.5rem);
        color: #262626;

        @media screen and (max-width: 50rem) {
          text-align: end;
          padding-top: 0;
          margin: 0;
          font-size: 1.2em;
        }
      }
    }
  }

  > .carLoading {
    background-color: lightgrey;
    position: relative;

    > div {
      > .overlay {
        position: absolute;
        background-color: #f6f6f6;
        opacity: 100%;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 12px;
      }
    }
  }

  > .stepsGrid {
    max-width: 44rem;
    margin: auto;

    @media screen and (max-width: 50rem) {
      min-width: auto;
      max-width: 100%;
    }

    > .stepsSession {
      display: flex;
      flex-direction: column;

      > .iconHeader {
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-bottom: 0;

        > h1 {
          font-weight: 400;
          font-style: normal;
          font-size: 1.5rem;
          margin: 0;
        }
      }

      > ul {
        margin-top: 0;
        list-style-type: none;
        padding-inline-start: 1.5em;

        > li {
          font-size: 0.875rem;
          line-height: 19.39px;
          list-style-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 0C3.25 0.0104167 2.57812 0.192708 1.98438 0.546875C1.38021 0.901042 0.901042 1.38021 0.546875 1.98438C0.192708 2.57812 0.0104167 3.25 0 4C0.0104167 4.75 0.192708 5.42188 0.546875 6.01562C0.901042 6.61979 1.38021 7.09896 1.98438 7.45312C2.57812 7.80729 3.25 7.98958 4 8C4.75 7.98958 5.42188 7.80729 6.01562 7.45312C6.61979 7.09896 7.09896 6.61979 7.45312 6.01562C7.80729 5.42188 7.98958 4.75 8 4C7.98958 3.25 7.80729 2.57812 7.45312 1.98438C7.09896 1.38021 6.61979 0.901042 6.01562 0.546875C5.42188 0.192708 4.75 0.0104167 4 0ZM5.5 4C5.48958 4.42708 5.34375 4.78125 5.0625 5.0625C4.78125 5.34375 4.42708 5.48958 4 5.5C3.57292 5.48958 3.21875 5.34375 2.9375 5.0625C2.65625 4.77083 2.51042 4.41667 2.5 4C2.51042 3.58333 2.65625 3.22917 2.9375 2.9375C3.21875 2.65625 3.57292 2.51042 4 2.5C4.42708 2.51042 4.78125 2.65625 5.0625 2.9375C5.34375 3.21875 5.48958 3.57292 5.5 4Z' fill='%23000000'/%3E%3C/svg%3E%0A");
          padding: 0.7rem;
        }
      }
    }

    > .thingsToNote {
      display: block;
      padding: 1rem 2rem;
      background: rgba(245, 245, 245, 0.6);
      border-radius: 12px;

      > div {
        display: flex;
        align-items: center;

        > h3 {
          padding-inline-start: 0.5rem;
          font-size: 0.8rem;
          font-weight: 700;
          line-height: 17.73px;
        }
      }

      > ul {
        margin: 0.3rem 0rem 0rem 1.3rem;
        li {
          font-size: 0.9rem;
          list-style-type: disc;
          padding-left: 3px;
          line-height: 20px;
        }
      }
    }

    > div {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 1rem;
      margin-top: 2.5rem;

      @media screen and (max-width: 50rem) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1.5rem;
      }
    }
  }

  .terms {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    transform: translateY(-2rem);

    > button {
      background-color: #0068ff;
      color: white;
      border: none;
      outline: none;
      border-radius: 2em;
      padding: 0.8rem;
      margin: auto;
      font-size: 1.125rem;
      width: 20rem;
      font-weight: 700;
      cursor: pointer;
      font-family: 'Corporate S', sans-serif;

      @media screen and (max-width: 30rem) {
        width: 100%;
        display: none;
      }
    }
  }

  > .assistanceBanner {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f5f5f5;
    border-radius: 12px 12px 0 0;
    margin-top: 2rem;

    > h3 {
      font-size: 1.563rem;
      line-height: 34.62px;
      font-weight: 700;
      margin: 3.125rem 0 1rem 0;
      padding: 0;

      @media screen and (max-width: 30rem) {
        font-size: 1.25rem;
      }
    }

    > p {
      max-width: calc(var(--max-width) - 30rem);
      margin: 0 2rem 3rem;
    }

    > .contactUs {
      width: 100%;
      display: flex;
      justify-items: center;
      align-items: center;

      > .imgContainer {
        width: 100%;
        max-width: 620px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        margin: auto;

        > img {
          width: 100%;
          height: auto;
          border-radius: 16px;
        }

        @media screen and (max-width: 50rem) {
          flex-direction: column;
          width: 90%;
          margin: auto;
          border-radius: 12px;
        }

        > .phoneInfo {
          background-color: #ffffff;
          position: absolute;
          padding: 1rem 1.8rem;
          border-radius: 12px;
          box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
          left: 1.4rem;
          top: 13.2rem;
          display: flex;
          place-items: center;

          > a {
            color: inherit;
            text-decoration: underline;
            text-decoration-thickness: 0.06em;
          }

          @media screen and (max-width: 50rem) {
            font-size: 1rem;
            left: -2rem;
            padding: 0.6rem 1rem;
          }

          > svg {
            padding-inline-end: 0.8rem;
          }
        }

        > .emailInfo {
          background-color: #ffffff;
          position: absolute;
          padding: 1rem 1rem;
          border-radius: 12px;
          box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
          left: -1.6rem;
          top: 8.6rem;
          display: flex;
          justify-content: center;
          align-items: center;

          > a {
            color: inherit;
            text-decoration: underline;
            text-decoration-thickness: 0.06em;
          }

          @media screen and (max-width: 50rem) {
            font-size: 1rem;
            left: -0.8rem;
            padding: 0.6rem 1rem;
          }

          > svg {
            padding-inline-end: 0.8rem;
          }
        }
      }

      &:last-child {
        margin-bottom: 3em;
      }
    }

    > .infoGrid {
      margin-top: 3rem;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 2rem;
      margin-bottom: 6.25rem;

      @media screen and (max-width: 75rem) {
        display: grid;
        justify-content: center;
        align-items: center;
        gap: 2rem;
      }

      @media screen and (max-width: 50rem) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
      }

      > .infoContainer {
        background: #ffffff;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        width: 20rem;
        height: 20rem;
        text-align: center;

        > .iconContainer {
          height: 8rem;
          width: 100%;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        > h3 {
          margin: 0;
          padding: 1rem 0;
          font-size: 1.25rem;
          width: 100%;
        }

        :nth-child(3) {
          margin: 0;
          padding: 1rem 0;
          font-weight: 600;
          padding: 0;
          margin: 0;
          font-size: 1rem;
          width: 100%;
        }

        > p {
          margin: 0 auto;
          padding: 1rem 0;
          width: 80%;
          font-weight: 300;
          font-size: 0.875rem;
        }
      }
    }
  }
}

.checkoutStickyContainer {
  width: 100%;
  display: flex;
  background-color: white;
  box-shadow: 0px -5px 12px rgba(0, 0, 0, 0.1);
  align-items: center;
  position: fixed;
  bottom: 0;
  padding: 0.5em;
  z-index: 5;

  .contentHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  button {
    background-color: #5bc2f4;
    color: white;
    border: none;
    outline: none;
    border-radius: 0.75rem;
    padding: 0.8rem;
    width: 18.8rem;
    font-size: 1rem;
    margin-left: 10.5rem;
    font-weight: 400;
    cursor: pointer;
    font-family: 'Corporate S', sans-serif;
  }

  .retailPricing {
    font-weight: 900;
    color: #000;
    //margin-top: -1em;
  }

  .priceDetails {
    display: flex;

    p:first-child {
      transform: translateY(0.35rem);
    }

    > .retailPricing {
      margin-left: 0.8em;
      font-size: 20px;
    }
  }

  @media screen and (max-width: 30rem) {
    padding: 0.5em;

    .retailPricing {
      font-weight: 700;
      color: #000;
      margin-left: 0 !important;
    }

    button {
      width: 12rem;
      margin: auto;
      font-size: 1rem;
      font-weight: 700;
      cursor: pointer;
      font-family: 'Corporate S', sans-serif;
    }

    .priceDetails {
      display: block;
      margin-left: 0.5rem;
    }
  }
}

.introLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.spacer {
  height: 4.2rem;
}

.curtainLoader {
  background-color: white;
  z-index: 100;
}
</style>
